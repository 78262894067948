import React from "react";
import CardGroup from 'react-bootstrap/CardGroup';
import CardsHomePage2 from "../components/CardsHomePage2";
import Typography from '@mui/material/Typography';

import logo_upgraders from '../images/logo_upgraders.png';
import logo_cosmetics from '../images/logo_cosmetics.png';
import logo_fingernails from '../images/logo_fingernails.png';
import logo_accessories from '../images/logo_accessories.png';
import logo_sexToys from '../images/logo_sexToys.png';
import logo_kitchen from '../images/logo_kitchen.png';
import logo_fashion from '../images/logo_fashion.png';
import logo_cars from '../images/logo_cars.png';

import logo_telegram from '../images/logo_telegram.png';

//import './App.css';
import AdsComponent from '../components/AdsComponent';

function Pirsum() {
        return (
                <div className="App-header" >
                        <div class="container">

                                <Typography variant="h4" >פירסום מוצרים</Typography>

                                <AdsComponent/>

                                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1351332554470174" crossorigin="anonymous"></script>
                                <ins class="adsbygoogle"
                                style="display:inline-block;width:728px;height:90px"
                                data-ad-client="ca-pub-1351332554470174"
                                data-ad-slot="1234567890"></ins>
                                <script>
                                (adsbygoogle = window.adsbygoogle || []).push({});
                                </script>


                                <CardGroup class="text-dark card-group" style={{ justifyContent:"center" }}>

                                        <CardsHomePage2
                                                headerText="טלגרם"
                                                mainLogo={logo_telegram}
                                                cardText="רוצים לחסוך זמן? כאן תראו את כל הערוצים במרוכז"
                                                url="https://t.me/addlist/LS2G8swGHQUwOGM0"
                                        />

                                        <AdsComponent/>

                                        <CardsHomePage2
                                                headerText="נייליסטיות"
                                                mainLogo={logo_fingernails}
                                                cardText="בייס, לקים, מדבקות, פצירות, צבעים, מרקמים, מקדחים וכל מה שיש לתחום הנייל להציע"
                                                url="https://t.me/upgradeFingernails"
                                        />

                                        <AdsComponent/>

                                        <CardsHomePage2
                                                headerText="קוסמטיקאיות"
                                                mainLogo={logo_cosmetics}
                                                cardText="ציוד מקצועי למגוון תחומי הקוסמטיקה במחירים משתלמים"
                                                url="https://t.me/cosmeticsUpgraders"
                                        />

                                        <AdsComponent/>

                                        <CardsHomePage2
                                                headerText="המשדרגים"
                                                mainLogo={logo_upgraders}
                                                cardText="הום סטיילינג, תאורה, איחסון, אלקטרוניקה, אבטחה, ומוצרים שישנו את הבית בסגנון האישי שלכם"
                                                url="https://t.me/upgraders_israel"
                                        />

                                        <AdsComponent/>

                                        <CardsHomePage2
                                                headerText="אקססוריז ותכשיטים"
                                                mainLogo={logo_accessories}
                                                cardText="טבעות, עגילים, שרשראות וצמידים, תיקים, ארנקים וכיסויים לטלפונים"
                                                url="https://t.me/accessoriesAndJewlery"
                                        />

                                        <AdsComponent/>

                                        <CardsHomePage2
                                                headerText="אופנה והנעלה"
                                                mainLogo={logo_fashion}
                                                cardText="ביגוד אופנתי ליום-יום, לאירועים, חולצות, שמלות, חצאיות, בגדי ספורט ונעליים "
                                                url="https://t.me/UpgradeFashionAndShoes"
                                        />

                                        <AdsComponent/>

                                        <CardsHomePage2
                                                headerText="המטבח שלי"
                                                mainLogo={logo_kitchen}
                                                cardText="סידור ואירגון המטבח, ציוד שמקל על הבישול וההכנות, כלים לאפייה ובישול, ודברי נוי בטעם אישי"
                                                url="https://t.me/upgradeMyKitchen"
                                        />

                                        <AdsComponent/>

                                        <CardsHomePage2
                                                headerText="אביזרים לרכב"
                                                mainLogo={logo_cars}
                                                cardText="הדברים הקטנים שעושים את ההבדל בין סתם רכב לרכב מותאם אישית, מתאים גם לאופנוענים"
                                                url="https://t.me/CarUpgrade"
                                        />

                                        <AdsComponent/>

                                        <CardsHomePage2
                                                headerText="פינוקים"
                                                mainLogo={logo_sexToys}
                                                cardText="למי שרוצה להיחשף לעולם חדש ומסקרן בדיסקרטיות מלאה"
                                                url="https://t.me/SexToysUpgraders"
                                        />

                                        <AdsComponent/>

                                </CardGroup>



                                <AdsComponent/>

                                <div class="container" style={{ marginTop: 40 }}>
                                        <a
                                                className="App-link"
                                                href="https://s.click.aliexpress.com/e/_AAdgIV"
                                                target="_blank" // open in a new tab
                                                rel="noopener noreferrer"
                                        >
                                                <Typography variant="h4">לאתר עלי אקספרס</Typography>

                                        </a>
                                </div>

                                <AdsComponent/>
                        </div>
                </div>
        );
}

export default Pirsum;
