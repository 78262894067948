import React from "react";
import CardGroup from 'react-bootstrap/CardGroup';
import CardsHomePage2 from "../components/CardsHomePage2";
import Typography from '@mui/material/Typography';

import logo_upgraders from '../images/logo_upgraders.png';


function Greek() {
        return (
                <div className="App-header" >
                        <div class="container">

                                <Typography variant="h4" >Προϊόντα Ali Express σε προσιτές τιμές</Typography>

                                <CardGroup class="text-dark card-group" style={{ justifyContent:"center" }}>

                                        <CardsHomePage2
                                                headerText="οι αναβαθμιστές"
                                                mainLogo={logo_upgraders}
                                                cardText="Φωτισμός, αποθήκευση, προϊόντα που θα βελτιώσουν το σπίτι σας στο προσωπικό σας στυλ"
                                                url="https://t.me/upgradersGreece"
                                        />

                                </CardGroup>




                                <div class="container" style={{ marginTop: 40 }}>
                                        <a
                                                className="App-link"
                                                href="https://s.click.aliexpress.com/e/_AAdgIV"
                                                target="_blank" // open in a new tab
                                                rel="noopener noreferrer"
                                        >
                                                <Typography variant="h4">Ιστοσελίδα Ali Express</Typography>

                                        </a>
                                </div>
                        </div>
                </div>
        );
}

export default Greek;
