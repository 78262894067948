import React from "react";
import CardGroup from 'react-bootstrap/CardGroup';
import CardsHomePage2 from "../components/CardsHomePage2";
import Typography from '@mui/material/Typography';

import logo_upgraders from '../images/logo_upgraders.png';
import logo_cosmetics from '../images/logo_cosmetics.png';
import logo_fingernails from '../images/logo_fingernails.png';

function Arabic() {
        return (
                <div className="App-header" >
                        <div class="container">

                                <Typography variant="h4" >منتجات علي اكسبريس بأسعار معقولة</Typography>

                                <CardGroup class="text-dark card-group" style={{ justifyContent:"center" }}>
                                        {/*  
                                        <CardsHomePage2
                                                headerText="المكياج والعناية"
                                                mainLogo={logo_cosmetics}
                                                cardText="محل ميكب سيتي البحيره ادكو بحري مسجد سيدي خلف, منتجات عناية بالبشرة والشعر ميكب وبيرفيوم"
                                                url="https://t.me/cosmeticsArab"
                                        />

                                        <CardsHomePage2
                                                headerText="أظافر"
                                                mainLogo={logo_fingernails}
                                                cardText="كن أكثر جمالا - منتجات الأظافر"
                                                url="https://t.me/fingernails_arab"
                                        />
                                        */}
                                        <CardsHomePage2
                                                headerText="المطورين"
                                                mainLogo={logo_upgraders}
                                                cardText="الإضاءة والتخزين والمنتجات التي من شأنها تحسين منزلك بأسلوبك الشخصي"
                                                url="https://t.me/upgradersArab"
                                        />

                                </CardGroup>


                                <div class="container" style={{ marginTop: 40 }}>
                                        <a
                                                className="App-link"
                                                href="https://s.click.aliexpress.com/e/_AAdgIV"
                                                target="_blank" // open in a new tab
                                                rel="noopener noreferrer"
                                        >
                                                <Typography variant="h4">موقع علي اكسبريس</Typography>

                                        </a>
                                </div>
                        </div>
                </div>
        );
}

export default Arabic;
